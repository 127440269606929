// Here, put your config. 

let config = {
    apiKey: "AIzaSyAC7mgfB8JG7pLuc787Fg8sEWl_NvuJgp0",
    authDomain: "shah-cloud-services.firebaseapp.com",
    databaseURL: "https://shah-cloud-services.firebaseio.com",
    projectId: "shah-cloud-services",
    storageBucket: "shah-cloud-services.appspot.com",
    messagingSenderId: "511211791983"
}
export default config
